export const environment = {
  production: true,
  appUrl: 'https://huey.billpocket.dev/',
  iauthUrl: 'https://iauth.billpocket.dev',
  apiUrl: 'https://api.operaciones.billpocket.dev/api',
  apiDistribuidores: 'https://api.osprey.billpocket.dev/api',
  apiTarjetaRevision: 'https://test.minuteman.billpocket.com/api',
  apiUsu: 'https://test.enterprise.billpocket.com/detalle/',
  apiFormaPago: 'https://api.adelaida.billpocket.dev/api/v1/bp/usuario',
  apiEnvio: 'https://test.nimitz.billpocket.com/envios',

  IDENTITY_POLL_ID: 'us-east-1:837cc09b-026d-41b9-a6ae-bc088f82fa43',
  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_C7Shck2DH',
  USER_POOL_WEB_CLIENT_ID: '52ufhnkh3rgfdn3e3rs24c3nbt',
  BUCKET: 'huey-uploads-operations-dev',
  BUCKET_FILE: 'huey-transactionality-reports-devel',
  REGION_AWS: 'us-east-1',
  urlS3: 'https://billpocket-solicitudes-tarjeta-demo.s3.us-east-2.amazonaws.com',
  urlCobros: 'https://test.moservice.billpocket.com',
  urlConciliacion: 'https://huey-operations-files.s3.us-east-2.amazonaws.com',
  conciliacionFolders: 'demo/conciliacion_fines_semana',
  estadoDeCuenta: 'https://test.dashboard.billpocket.com',
  password: '3st4d0Cu3nT@BP',
  EXPIRE_URL: 604800,
  tasa_default_credito: 2.5,
  tasa_default_debito: 2.5,
  tasa_default_amex: 3.2,
  tasa_default_internacional: 2.7,
  tasa_default_teclado_abierto: 2.7,
  KUSHKI_INTEGRATION: 'KUSHKI_INTEGRATION',
  BP_PREFIX: 'BPK'
};
